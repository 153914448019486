/* Pagination.css */

.pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  .page-item {
    margin: 0 3px;
  }
  
  .page-link {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    color: #007bff;
    text-decoration: none;
  }
  
  .page-link:hover {
    background-color: #e9ecef;
    border-color: #ddd;
  }
  
  .active-link {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
  }
  
  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    border-color: #ddd;
  }
  